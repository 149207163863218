import React from "react"
import PropTypes from "prop-types"

const UspBand = ({ usps, largeHeadings }) => (
  <div className="bg-secondary py-12 md:py-24 overflow-hidden mb-16 lg:mb-32">
    <div className="container">
      <div className="flex flex-wrap lg:-mx-6">
        {usps.map(({heading, blurb}, i) => {
          let classNames = `w-full lg:px-6 text-center`;

          if (usps.length === 3) {
            { blurb.length ? ( classNames += ` lg:w-1/3 mb-12 lg:mb-0` ) : ( classNames += ` lg:w-1/3 my-4 lg:mb-0` )}
            
          } else if (usps.length === 4) {
            classNames += ` lg:w-1/2 mb-12`;
          } else {
            classNames += ` lg:w-1/3 mb-4`;
          }

          return (
            <div
              key={i}
              className={classNames}
            >
              { blurb.length ? (
                <div>
                <span className={largeHeadings ? `h3 text-secondary-light mb-5 block` : `h4 text-secondary-light mb-5 block`}>{heading}</span>
                <div className="text-white lg:px-8 text-lg" dangerouslySetInnerHTML={{ __html: blurb }}></div>
                </div>
              ) : (
                <span className={largeHeadings ? `h3 text-secondary-light mb-5 block` : `h4 text-secondary-light mb-0 block`}>{heading}</span>
              )}
            </div>
          )
        })}
      </div>
    </div>
  </div>
)

UspBand.propTypes = {
  usps: PropTypes.array,
  largeHeadings: PropTypes.bool,
}

UspBand.defaultProps = {
  usps: [],
  largeHeadings: false,
}

export default UspBand